import { computed } from 'vue'
  
import requests from '@/backend/requests'
import { residentialId } from '@/store'
import { properties, loading, limit } from './state'
import modelProperty from '@/models/property'
import {
  maxRoomsNumber,
  priceMin,
  priceMax,
  areaMin,
  areaMax,
  maxFloor,
  optionsDeadline,
  valueRooms,
  priceFrom,
  priceTo,
  areaFrom,
  areaTo,
  floorFrom,
  floorTo,
  deadline,
  isStudio,
  isFacing,
  characteristics,
  isFacingExist
} from './Filter/state'

// PROPERTIES
const propertiesGetter = computed( () => properties.value )
const loadingGetter = computed( () => loading.value )
const propertiesOrderedGetter = computed( () => {
  return propertiesGetter.value.sort( ( a, b ) => a.price - b.price )
} )
const propertiesLimitedGetter = computed( () => propertiesOrderedGetter.value.slice( 0, limit.value ) )

const fetchPropertiesIteration = async options => {
  const response = await requests.getProperty( options )
  const data = response.data.data
  properties.value = properties.value.concat( data.map(
    property => modelProperty( property )
  ) )
  if ( data.length >= 100 ) {
    options.params.offset += 100
    await fetchPropertiesIteration( options )
  }
}

const fetchProperties = async () => {
  limit.value = 8
  const projectId = residentialId.value
  const propertyTypeAliases = [ 'property' ]
  const params =  { projectId, propertyTypeAliases, full: true, offset: 0 }
  const options = { params }
  properties.value = []
  loading.value = true
  try {
    await fetchPropertiesIteration( options )
  } finally {}
}

const init = async () => {
  await fetchProperties()

  properties.value = properties.value.filter( el => ( el.status === 'AVAILABLE' ) )
  
  const houses = ( await requests.getHouse( { params: { projectId: residentialId.value } } ) ).data

  maxRoomsNumber.value = Math.max( ...properties.value.map( el => el.roomsAmount ) )

  priceMin.value = Math.min( ...properties.value.map( el => el.price ) )
  priceMax.value = Math.max( ...properties.value.map( el => el.price ) )

  areaMin.value = Math.min( ...properties.value.map( el => el.area ) )
  areaMax.value = Math.max( ...properties.value.map( el => el.area ) )

  maxFloor.value = Math.max(...properties.value.map(el => el.floor))

  isFacingExist.value = properties.value.filter( el => el?.facing )?.length
  
  const houseIds = Array.from( new Set( properties.value.map( el => el.houseId ) ) )
  const housesTarget = houses.filter( el => houseIds.includes( el.profitbase.id ) )

  console.log( 'housesTarget', housesTarget );
  optionsDeadline.value = Array.from( ( new Set( housesTarget.filter( el => !!el.profitbase.developmentEndQuarter ).filter( el => ( el.profitbase.buildingState !== 'HAND-OVER' ) ).map( el => JSON.stringify( el.profitbase.developmentEndQuarter ) ) ) ) ).map( el => JSON.parse( el ) )
  optionsDeadline.value.forEach( el => (
    el.title = `${ el.year } г. - ${ [ 'I', 'II', 'III', 'IV' ][ el.quarter - 1 ] } квартал`
  ) )
  const handOverHouses = houses.filter( el => ( el.profitbase.buildingState === 'HAND-OVER' ) )
  const handOverAvaliableProperty = properties.value.find( el => ( handOverHouses.map( el => el.profitbase.id ) || [] ).includes( el.houseId ) )
  if ( handOverAvaliableProperty )
    optionsDeadline.value.push( { key: 'finished', title: 'сдан' } )

  loading.value = false
}

const fetchPropertiesByFilter = async () => {
  limit.value = 8
  const projectId = residentialId.value
  const propertyTypeAliases = [ 'property' ]
  const params =  {
    projectId, propertyTypeAliases, full: true, offset: 0,
    'rooms[]': valueRooms.value,
    'price[min]': priceFrom.value,
    'price[max]': priceTo.value,
    'area[min]': areaFrom.value,
    'area[max]': areaTo.value
  }
  if ( deadline.value?.key === 'finished' )
    params.isHouseFinished = true
  else {
    params.developmentEndYear = deadline.value?.year || null,
    params.developmentEndQuarter = deadline.value?.quarter || null
  }
  const options = { params }
  loading.value = true
  properties.value = []
  try {
    await fetchPropertiesIteration( options )
    properties.value = properties.value.filter( el => ( ( el.floor >= floorFrom.value ) && ( el.floor <= floorTo.value ) ) )
    properties.value = properties.value.filter( el => ( el.status === 'AVAILABLE' ) )
    if ( isFacing.value ) {
      properties.value = properties.value.filter( el => (
        [ 'чистовая', 'предчистовая' ]
          .includes( el.facing?.toLowerCase() )
      ) )
    }
    if ( isStudio.value ) properties.value = properties.value.filter( el => el.isStudio )
    const filterCharacteristics = characteristics.value.filter( el => el.active )
    filterCharacteristics.forEach( char => {
      properties.value = properties.value.filter( el => (
        el.customFields.find( fld => (
          fld.id === char.id
        ) ).value
        === 'Есть'
      ) )
    } )
  } finally {
    loading.value = false
  }
}

export {
  propertiesGetter as properties,
  propertiesLimitedGetter as propertiesLimited,
  loadingGetter as loading,
  fetchProperties,
  init,
  fetchPropertiesByFilter
}
  
// LIMIT
const isLimitPossibleGetter = computed( () => properties.value.length > limit.value )

const limitIncrease = () => limit.value += 8

export {
  isLimitPossibleGetter as isLimitPossible,
  limitIncrease
}

export const characteristicsFiltered = computed( () => {
  const propertiesCharacteristics = properties.value.reduce( ( acc, el ) => (
    acc.concat( el.customFields )
  ), [] )
  const existCharacteristics = propertiesCharacteristics.filter( el => (
    el.value === 'Есть'
  ) )
  const existCharacteristicsIds = [ ...new Set( existCharacteristics.map( el => el.id ) ) ]
  const result = characteristics.value.filter( el => (
    existCharacteristicsIds.includes( el.id )
  ) )
  return result
} )