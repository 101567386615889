import axios from 'axios'
import { APIRoot, accessToken } from './config'

const instance = axios.create( {
  baseURL: APIRoot
} )

instance.interceptors.request.use( config => {
  if ( !( config.params ) ) config.params = {}
  config.params[ 'access-token' ] = 'ePUqk2n4rU2HSVUJu3VIqyH4UIhOvGydYfFa9mzhyc89' && accessToken
  return config
} )

export default instance