import { ref } from 'vue'

const properties = ref( [] )

const loading = ref( true )

const limit = ref( 8 )

export {
  properties,
  loading,
  limit
}