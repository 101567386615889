<template>
  <div class="select"
    v-bind:class="{ expanded }"
  >
    <BaseInput class="input text-black at-dark" readonly style="text-align: left;"
      ref="input"
      v-bind:value="modelValue && getterTitle( modelValue )"
      v-bind:disabled="disabled"
      v-on:keydown.enter.space.prevent="onKeyDownInput"
      v-on:click="onClick"
    />
    <div class="postfix">
      <IconArrow class="icon at-dark"/>
    </div>
    <BaseList class="list"
      ref="list"
      v-show="expanded"
      v-bind:modelValue="modelValue"
      v-bind:options="options"
      v-bind:getterTitle="getterTitle"
      v-on:update:modelValue="onUpdateModelValue"
      v-on:blur="onBlur"
      v-on:keydown.space.prevent
      v-on:keydown.enter.esc="onKeyDownList"
      v-on:click="onClickList"
    />
  </div>
</template>

<script setup>
  import { ref, nextTick, defineProps, defineEmits } from 'vue'
  import BaseInput from '../Input'
  import IconArrow from '../../Icon/Arrow'
  import BaseList from '../List'

  defineProps( {
    modelValue: {},
    options: {},
    getterTitle: {
      type: Function,
      default: option => option
    },
    disabled: {
      type: Boolean
    }
  } )

  const emit = defineEmits( [ 'update:modelValue' ] )

  const onUpdateModelValue = value => emit( 'update:modelValue', value )

  const expanded = ref( false )

  const input = ref( null )
  const list = ref( null )

  const expand = () => {
    expanded.value = true
    nextTick( () => list.value.$el.focus() )
  }

  const collapse = () => {
    expanded.value = false
  }

  const onKeyDownInput = expand

  const onBlur = event => {
    if ( event.relatedTarget === input.value.$el ) return
    collapse()
  }

  const onKeyDownList = () => {
    collapse()
    nextTick( () => input.value.$el.focus() )
  }

  const onClick = () => {
    expanded.value ? collapse() : expand()
  }

  const onClickList = () => {
    input.value.$el.focus()
    expanded.value = false
  }
</script>