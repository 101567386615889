import { ref } from 'vue'

const maxRoomsNumber = ref( 3 )

const priceMin = ref( 0 )
const priceMax = ref( 10000000 )

const areaMin = ref( 0 )
const areaMax = ref( 170 )

const maxFloor = ref( 5 )

const optionsDeadline = ref( [] )

const valueRooms = ref( null )

const priceFrom = ref( null )
const priceTo = ref( null )

const areaFrom = ref( null )
const areaTo = ref( null )

const floorFrom = ref( null )
const floorTo = ref( null )

const deadline = ref( null )

export {
  maxRoomsNumber,
  priceMin,
  priceMax,
  areaMin,
  areaMax,
  maxFloor,
  optionsDeadline,
  valueRooms,
  priceFrom,
  priceTo,
  areaFrom,
  areaTo,
  floorFrom,
  floorTo,
  deadline
}