import { ref } from 'vue'
import requests from '@/backend/requests'
import { residentialId } from '@/store'
import { options } from './Сontent/state'

const loading = ref( true )

let findedProperty = false
let findedParking = false
let findedCommercialPremises = false

const buildTabsIteration = async offset => {
  const response = await requests.getProperty( { params: { full: true, projectId: residentialId.value, offset } } )
  const data = response.data.data
  if ( !( findedProperty ) ) findedProperty = !!( data.find( el => el.propertyType === 'property' ) )
  if ( !( findedParking ) ) findedParking = !!( data.find( el => el.propertyType === 'parking' ) )
  if ( !( findedCommercialPremises ) ) findedCommercialPremises = !!( data.find( el => el.propertyType === 'commercial_premises' ) )
  if ( data.length >= 100 && ( !( findedProperty && findedParking && findedCommercialPremises ) ) )
    await buildTabsIteration( offset + 100 )
}

const buildTabs = async () => {
  options.value = []
  // await buildTabsIteration( 0 )
  await Promise.all( [
    ( async () => {
      const response = await requests.getProperty( { params: { projectId: residentialId.value, limit: 1, 'status[]': 'AVAILABLE', 'propertyTypeAliases[]': 'property' } } )
      findedProperty = !!( response.data.data.length )
    } )(),
    ( async () => {
      const response = await requests.getProperty( { params: { projectId: residentialId.value, limit: 1, 'status[]': 'AVAILABLE', 'propertyTypeAliases[]': 'parking' } } )
      findedParking = !!( response.data.data.length )
    } )(),
    ( async () => {
      const response = await requests.getProperty( { params: { projectId: residentialId.value, limit: 1, 'status[]': 'AVAILABLE', 'propertyTypeAliases[]': 'commercial_premises' } } )
      findedCommercialPremises = !!( response.data.data.length )
    } )(),
  ] )
  if ( findedProperty ) options.value.push( { slug: 'flat', title: 'Выбор квартир', text: 'Квартиры' } )
  if ( findedParking ) options.value.push( { slug: 'parking', title: 'Выбор парковок', text: 'Парковки' } )
  if ( findedCommercialPremises ) options.value.push( { slug: 'office', title: 'Выбор офисов', text: 'Офисы' } )
  loading.value = false
}

export { loading, buildTabs }