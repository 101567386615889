<template>
  <div>
    <MainLoading
      v-if="loading"
    />

    <MainContent
      v-else
    />
  </div>
</template>

<script setup>
  import MainLoading from './Loading'
  import MainContent from './Сontent'
  import { loading, buildTabs } from './module'

  buildTabs()
</script>