import { computed } from 'vue'
import modelProperty from '@/models/property'
import { visibility, propertyId } from './state'

const visibilityGetter = computed( () => visibility.value )
const propertyIdGetter = computed( () => propertyId.value )

const show = () => visibility.value = true
const hide = () => visibility.value = false

const setHash = id => window.location.hash = `#property_id=${ id }`
const resetHash = id => window.location.hash = ''

const lockBody = () => document.body.style.overflowY = 'hidden'
const unlockBody = () => document.body.style.overflowY = null

const openByPropertyId = id => {
  lockBody()
  show()
  setHash( id )
  propertyId.value = id
}

const close = () => {
  let documentTarget = document.documentElement || document.body
  let scrollTop = documentTarget.scrollTop
  unlockBody()
  hide()
  propertyId.value = null
  resetHash()
  documentTarget.scrollTop = scrollTop
}

const initByHash = () => {
  const hash = window.location.hash
  const matched = hash.match( /(#property_id=)(\d{0,})/ )
  if ( matched && matched.length && matched[ 2 ] ) {
    const id = matched[ 2 ]
    openByPropertyId( id )
  }
}

initByHash()

export {
  visibilityGetter as visibility,
  propertyIdGetter as propertyId,
  openByPropertyId,
  close
}