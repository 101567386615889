<template>
  <div class="range">
    <input type="text"
      ref="input"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, onDeactivated, defineProps, defineEmits, toRefs, watch } from 'vue'
  // import jquery from 'jquery'
  // import 'ion-rangeslider'
  // import 'ion-rangeslider/css/ion.rangeSlider.min.css'

  const props = defineProps( {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    modelValueFrom: {
      type: Number,
      default: 0
    },
    modelValueTo: {
      type: Number,
      default: 100
    },
    disabled: {
      type: Boolean,
      default: false
    }
  } )

  const emit = defineEmits( [ 'update:modelValueFrom', 'update:modelValueTo' ] )

  const { min, max, step, modelValueFrom, modelValueTo, disabled } = toRefs( props )

  const input = ref( null )
  const slider = ref( null )

  onMounted( () => {
    $( input.value ).ionRangeSlider( {
      type: "double",
      min: min.value,
      max: max.value,
      step: step.value,
      from: min.value,
      to: max.value,
      block: disabled.value,
      onChange: data => {
        emit( 'update:modelValueFrom', data.from )
        emit( 'update:modelValueTo', data.to )
      },
      onStart: data => {
        emit( 'update:modelValueFrom', data.from )
        emit( 'update:modelValueTo', data.to )
      },
      onUpdate: data => {
        emit( 'update:modelValueFrom', data.from )
        emit( 'update:modelValueTo', data.to )
      }
    } )

    slider.value = $( input.value ).data( 'ionRangeSlider' )
  } )

  watch( [ min, max, step ], () => {
    slider.value.update( {
      type: "double",
      min: min.value,
      max: max.value,
      step: step.value,
      from: min.value,
      to: max.value,
      block: disabled.value
    } )
  } )

  watch( disabled, () => {
    slider.value.update( {
      type: "double",
      min: min.value,
      max: max.value,
      step: step.value,
      from: modelValueFrom.value,
      to: modelValueTo.value,
      block: disabled.value
    } )
  } )

  onDeactivated( () => {
    slider.value.destroy()
  } )
</script>