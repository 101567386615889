<template>
  <ul class="control list" tabindex="0"
    v-on:keydown.up.prevent="onKeyDownUp"
    v-on:keydown.down.prevent="onKeyDownDown"
  >
    <li class="item"
      v-for="( option, index ) of options"
      v-bind:key="index"
      v-bind:class="{ active: option === optionHover }"
      v-on:click="onClick( option )"
      v-on:mouseenter="onMouseEnter( option )"
    >
      <span>{{ getterTitle( option ) }}</span>
    </li>
  </ul>
</template>

<script setup>
  import { defineProps, defineEmits, toRefs, ref } from 'vue'

  const props = defineProps( {
    modelValue: {},
    options: {
      type: Array,
      default: () => []
    },
    getterTitle: {
      type: Function,
      default: option => option
    }
  } )

  const emit = defineEmits( [ 'update:modelValue' ] )

  const { modelValue, options } = toRefs( props )

  const updateModelValue = value => {
    optionHover.value = value
    emit( 'update:modelValue', value )
  }

  const optionHover = ref( null )

  const onClick = updateModelValue

  const onKeyDownUp = () => {
    if ( !( optionHover.value ) ) return updateModelValue( options.value[ options.value.length - 1 ] )
    const index = options.value.findIndex( el => el === optionHover.value )
    const targetIndex = ( ( index - 1 ) < 1 ) ? 0 : ( index - 1 )
    const target = options.value[ targetIndex ]
    updateModelValue( target )
  }

  const onKeyDownDown = () => {
    if ( !( optionHover.value ) ) return updateModelValue( options.value[ 0 ] )
    const index = options.value.findIndex( el => el === optionHover.value )
    const targetIndex = ( ( index + 1 ) >= options.value.length ) ? ( options.value.length - 1 ) : ( index + 1 )
    const target = options.value[ targetIndex ]
    updateModelValue( target )
  }

  const onMouseEnter = option => optionHover.value = option
</script>