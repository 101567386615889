import axios from './axios'

const requests = {
  getProperty: options => {
    return axios.get( '/property', options )
  },
  
  getPropertyTypes: options => {
    return axios.get( '/property-types', options )
  },
  
  getHouse: options => {
    return axios.get( '/houses', options )
  },
  
  getFloor: ( houseId, options ) => {
    return axios.get( `/floor/${ houseId }`, options )
  }
}

export default requests