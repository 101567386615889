<template>
  <div>
    <FlatFilter />
    <FlatList />
  </div>
</template>

<script setup>
  import FlatFilter from './Filter'
  import FlatList from './List'
  import { init } from  './module'

  init()
</script>