import { computed, ref, watch } from 'vue'
import requests from '@/backend/requests'
import modelProperty from '@/models/property'
import { propertyId } from '../state'
import { property, loading, detailed } from './state'

const propertyGetter = computed( () => property.value )
const loadingGetter = computed( () => loading.value )
const detailedGetter = computed( () => detailed.value )

const floor = ref( null )

const fetchProperty = async () => {
  detailed.value = true
  const id = propertyId.value
  const params = { id, full: true }
  const options = { params }
  try {
    loading.value = true
    const response = await requests.getProperty(options)
    const data = response.data.data[ 0 ]
    property.value = modelProperty( data )
  } finally {
  }
}

const toViewFloor = () => detailed.value = false

const viewBox = ref( null )

const init = async () => {
  await fetchProperty()
  floor.value = ( ( await requests.getFloor( property.value.houseId ) ).data ).find( el => el.number === property.value.floor )
  const image = new Image()
  image.onload = () => {
    viewBox.value = `0 0 ${ image.width } ${ image.height }`
  }
  loading.value = false
  image.src = floor.value?.images?.source
}

watch( propertyId, () => init(), { immediate: true } )

export {
  propertyGetter as property,
  loadingGetter as loading,
  detailedGetter as detailed,
  fetchProperty,
  toViewFloor,
  init,
  floor,
  viewBox
}