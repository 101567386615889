import { ref } from 'vue'

const maxRoomsNumber = ref( 3 )

const priceMin = ref( 0 )
const priceMax = ref( 10000000 )

const areaMin = ref( 0 )
const areaMax = ref( 170 )

const maxFloor = ref( 5 )

const optionsDeadline = ref( [] )

const valueRooms = ref( null )

const priceFrom = ref( null )
const priceTo = ref( null )

const areaFrom = ref( null )
const areaTo = ref( null )

const floorFrom = ref( null )
const floorTo = ref( null )

const deadline = ref( null )

const isStudio = ref( false )
const isFacing = ref( false )

const isFacingExist = ref( false )

const characteristics = ref( [
  { active: false, title: 'Кухня-гостиная', id: "pbcf_64883efeb69f1", name: "Кухня-гостиная", rank: 44 },
  { active: false, title: 'Мастер спальня', id: "pbcf_64883f126ccdb", name: "Мастер-спальня", rank: 48 },
  { active: false, title: 'Высота потолков более 3х метров', id: "pbcf_64883f0524cda", name: "Потолки более 3м", rank: 45 },
  { active: false, title: 'Панорамное остекление', id: "pbcf_64883f08bdd1e", name: "Панорамное остекление", },
  { active: false, title: 'Пентхаус', id: "pbcf_64883f1ac1880", name: "Пентхаус", rank: 49 },
  { active: false, title: 'Патио', id: "pbcf_64883f44df449", name: "Патио", rank: 51 },
  { active: false, title: 'Терраса', id: "pbcf_64883f2cc8da2", name: "Терраса", rank: 50 },
  // { active: false, title: 'Второй свет' },
  { active: false, title: 'Более 2х санузлов', id: "pbcf_64a7c22f3eda1", name: "Более 2х санузлов", rank: 52 },
  { active: false, title: 'Вид во двор', id: "pbcf_64a7c24ebcbfd", name: "Вид во двор", rank: 54 },
  { active: false, title: 'Угловое окно', id: "pbcf_64a7c2399fae5", name: "Угловое окно", rank: 53 }
] )

export {
  maxRoomsNumber,
  priceMin,
  priceMax,
  areaMin,
  areaMax,
  maxFloor,
  optionsDeadline,
  valueRooms,
  priceFrom,
  priceTo,
  areaFrom,
  areaTo,
  floorFrom,
  floorTo,
  deadline,
  isStudio,
  isFacing,
  characteristics,
  isFacingExist
}